<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="568px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="姓名：" prop="name">
                    <el-input v-model.trim="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="身份：" prop="type">
                    <el-radio-group v-model="formData.type" @change="handleChange">
                        <el-radio :label="1">組員</el-radio>
                        <el-radio :label="2">組長</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="歸屬組長：" prop="belong_leader" class="is-required" v-if="formData.type == 1">
                    <el-select v-model="formData.belong_leader" placeholder="请选择" clearable filterable style="width: 100%;">
                        <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { twymJieshao, twymJieshaoAdd, twymJieshaoEdit } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog },
    emits: ['refreshData', 'closed'],
    name: "moduleJieshao",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                name: '',
                type: 1,
                belong_leader: ""
            },
            rules: {
                name: [
                    { required: true, message: '请填写姓名', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择身份', trigger: 'blur' }
                ],
            },
            typeNameList: [
                { id: 1, name: '组员' },
                { id: 2, name: '组长' },
            ],
            typeList: []
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            this.getZhlList()
            if (item) {
                this.formData = deepClone(item)
            }
        },
        handleChange(e) {
            if (e == 1 && this.typeList.length <= 0) {
                this.getZhlList()
            } else {
                this.formData.belong_leader = ""
            }
        },
        getZhlList() {
            twymJieshao({ page: 1, limit: 999, type: 2 }).then(res => {
                if (res.code == 2000) {
                    this.typeList = res.data.data
                }
            })
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (param.type == 1 && !param.belong_leader) {
                        this.$message.warning("请选择组长")
                        return
                    }
                    if (this.formData.id) {
                        twymJieshaoEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.$emit('refreshData')
                                this.handleClose()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        twymJieshaoAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.$emit('refreshData')
                                this.handleClose()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

