<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="美容師：">
                    <el-input v-model.trim="formInline.mrs" maxlength="60" clearable placeholder="美容師" @change="search"
                        style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="时间范围：">
                    <el-date-picker style="width:350px" v-model="timers" type="datetimerange" @change="timeChange"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>总金额：{{ orderstatics.totalAmount }}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <!-- <el-table-column min-width="70" prop="avatar" label="用户头像">
                <template #default="scope">
                    <img :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg"
                        style="width: 30px;height: 30px" :onerror="defaultImg">
                </template>
            </el-table-column> -->
            <el-table-column min-width="110" prop="creator_name" label="美容师姓名"></el-table-column>
            <el-table-column min-width="120" prop="program_name" label="名稱"></el-table-column>
            <el-table-column width="130" prop="staff_commission" label="獎金金額"></el-table-column>
            <el-table-column min-width="180" prop="create_datetime" label="支付時間"></el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats } from "@/utils/util";
import { twymProgramRecharge } from '@/api/api'
import { lyMixins } from "@/mixins/mixins"
export default {
    mixins: [lyMixins],
    components: {
        Pagination,
    },
    name: 'productOrderStatistics',
    data() {
        return {
            isFull: false,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            orderstatics: {
                totalAmount: 0,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            tableData: [],
            timers: [],
        }
    },
    methods: {
        setFull() {
            this.isFull = !this.isFull
        },
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        handleEdit(row, flag) {
            let vm = this
            if (flag == 'shop') {
                this.$router.push({ name: 'shopStatistics', query: { id: row.id, name: row.name } })
            }
            else if (flag == 'clerk') {
                this.$router.push({ name: 'clerkStatistics', query: { id: row.id, name: row.name } })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.timers = []
                this.search()
            }
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            twymProgramRecharge(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data.data
                    this.orderstatics.totalAmount = res.data.data.total_amount
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },
        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFile(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportData() {
            // var params = {
            //     type:3,
            //     page: 1,
            //     limit: 9999,
            // }
            // retrieveFinancestatisticsExport(params).then(res => {
            //      if(res.code ==2000) {
            //          this.downloadFile(res.data.data)
            //          //this.$message.warning(res.data.data)
            //      }
            //  })
        }
    },
    created() {
        this.getData()
    },
}
</script>
<style lang="scss">
.order-static {
    display: flex;
    font-size: 14px;
    background: var(--l-order-statics);
    padding: 10px;

    li {
        margin-right: 30px;

    }
}
</style>