<template>
    <div v-dialogDrag>
        <el-dialog :title="loadingTitle" v-model="dialogVisible" width="680px" top="20px" :destroy-on-close="true"
            :close-on-click-modal="false" :before-close="handleClose">
            <el-form :inline="false" :model="formData" ref="rulesForm" label-position="right" label-width="auto"
                :disabled="true">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="姓名：" prop="name">
                            {{ formData.name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="出生日期：" prop="birthday">
                            {{ formData.birthday }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="年齡：" prop="age">
                            {{ formData.age }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别：" prop="gender">
                            {{ formData.gender }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份證號：" prop="idcard">
                            {{ formData.idcard }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="職業：" prop="zhiye">
                            {{ formData.zhiye }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="婚姻狀態：" prop="hunyin">
                            {{ formData.hunyin }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="聯絡地址：" prop="place">
                            {{ formData.place }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="電話號碼：" prop="mobile">
                            {{ formData.mobile }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="電子郵箱：" prop="email">
                            {{ formData.email }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Line ID：" prop="lineid">
                            {{ formData.lineid }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="介紹人：" prop="jieshao_name">
                            {{ formData.jieshao_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否做過醫美項目：" prop="is_yimei">
                            <el-radio-group v-model="formData.is_yimei">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="對自己是否有過度要求：" prop="is_gd_yaoqiu">
                            <el-radio-group v-model="formData.is_gd_yaoqiu">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="是否有身心障礙手册：" prop="is_za_che">
                            <el-radio-group v-model="formData.is_za_che">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="皮膚厚薄粗厚部分：" prop="fu_hou">
                            <el-input v-model="formData.fu_hou" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="皮膚厚薄脆弱部分：" prop="fu_bo">
                            <el-input v-model="formData.fu_bo" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="皺紋">
                            <el-checkbox-group v-model="zhouwen">
                                <el-checkbox label="油性" />
                                <el-checkbox label="乾性" />
                                <el-checkbox label="皺紋" />
                                <el-checkbox label="鬆弛" />
                                <el-checkbox label="老化" />
                                <el-checkbox label="瘡" />
                                <el-checkbox label="黑斑" />
                                <el-checkbox label="不㵖" />
                                <el-checkbox label="敏感" />
                                <el-checkbox label="傷疤" />
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="" prop="is_jibing">
                            是否有遗傅/慢性病史：
                            <el-radio-group v-model="formData.is_jibing">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label=" ">
                            <el-checkbox-group v-model="jibing">
                                <el-checkbox label="氣喘或呼吸道疾病" />
                                <el-checkbox label="敏感醴鬟/過敏原" />
                                <el-checkbox label="高血壓" />
                                <el-checkbox label="心臟病" />
                                <el-checkbox label="胃臟病" />
                                <el-checkbox label="糖尿病" />
                                <el-checkbox label="B型肝炎" />
                                <el-checkbox label="C型肝炎" />
                                <el-checkbox label="免疫系統疾病" />
                                <el-checkbox label="（膽固醇）激素依賴行皮炎" />
                                <el-checkbox label="貧血" />
                                <el-checkbox label="心血管疾病" />
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="" prop="is_guomin">
                            是否有菜物過敏：
                            <el-radio-group v-model="formData.is_guomin">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label=" ">
                            <el-checkbox-group v-model="guomin">
                                <el-checkbox label="皮林" />
                                <el-checkbox label="止痛藥" />
                                <el-checkbox label="抗生素" />
                                <el-checkbox label="傅明酸" />
                                <el-checkbox label="消炎藥" />
                                <el-checkbox label="麻醉藥" />
                                <el-checkbox label="其他" />
                                <el-input v-model="formData.guomin_other"></el-input>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="" prop="is_yao">
                            目前是否有服藥：
                            <el-radio-group v-model="formData.is_yao">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label=" ">
                            <el-checkbox-group v-model="yao">
                                <el-checkbox label="銀杏" />
                                <el-checkbox label="止痛藥" />
                                <el-checkbox label="Vit C" />
                                <el-checkbox label="抗凝血劑" />
                                <el-checkbox label="大豆異黃酮" />
                                <el-checkbox label="麻醉藥" />
                                <el-checkbox label="其他" />
                                <el-input v-model="formData.yao_other"></el-input>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="是否懷孕：" prop="is_yun">
                            <el-radio-group v-model="formData.is_yun">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="其他：" prop="other">
                            <el-input v-model="formData.other" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="客戶簽名：" prop="sign">
                            <el-image :src="formData.sign" preview-teleported></el-image>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { UsersUsersDetial } from "@/api/api";
export default {
    name: "moduleDetail",
    emits: ['refreshData'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            formData: {
            },
            zhouwen: [],
            jibing: [],
            guomin: [],
            yao: [],
        }
    },
    methods: {

        handleClose() {
            this.dialogVisible = false
            this.loadingSave = false
            this.$emit('refreshData')
        },
        addUserFn(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true

            this.getDetail(item.id)
        },
        getDetail(id) {
            UsersUsersDetial({ id: id }).then(res => {
                this.formData = res.data.data
                this.zhouwen = this.formData.zhouwen.split(";")
                this.jibing = this.formData.jibing.split(";")
                this.guomin = this.formData.guomin.split(";")
                this.yao = this.formData.yao.split(";")
            })
        }
    }
}
</script>

