<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="套組名稱：" prop="name">
                    <el-input v-model.trim="formData.name" maxlength="10" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="原價：" prop="original_price">
                    <div style="display: flex;align-items: center;width: 100%;">
                        <lyInputNumber v-model="formData.original_price" style="width: 100%;" :controls="false" :precision="2">
                        </lyInputNumber>NT$
                    </div>
                </el-form-item>
                <el-form-item label="現金金額：" prop="xj_amount">
                    <div style="display: flex;align-items: center;width: 100%;">
                        <lyInputNumber v-model="formData.xj_amount" style="width: 100%;" :controls="false" :precision="2">
                        </lyInputNumber>NT$
                    </div>
                </el-form-item>
                <el-form-item label="產品儲值金額：" prop="send_amount">
                    <div style="display: flex;align-items: center;width: 100%;">
                        <lyInputNumber v-model="formData.send_amount" style="width: 100%;" :controls="false" :precision="2">
                        </lyInputNumber>NT$
                    </div>
                </el-form-item>
                <el-form-item prop="data" label="擁有項目：">
                    <el-table :data="formData.data" ref="lyaddtable" border stripe>
                        <el-table-column type="index" width="60" fixed="left">
                            <template #header>
                                <LyTableSelect button-type="primary" placement="right" :tableIndex="false"
                                    button-icon="plus" size="small" :button-circle="true" ref="lymanualSelect"
                                    :apiObj="getGoodsApi" @click="handleManualSelectClick" :table-width="600" multiple
                                    @confirm="handleManualConfirmClick">
                                    <template #header="{ form, submit }">
                                        <el-form :inline="true" :model="form">
                                            <el-form-item>
                                                <el-input type="text" v-model="form.name" placeholder="項目名称"
                                                    style="width: 160px;" clearable size="small"></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="submit" size="small">查询</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </template>
                                    <el-table-column prop="img" label="图片" width="80">
                                        <template #default="scope">
                                            <el-image :src=scope.row.img :preview-src-list="[scope.row.img]"
                                                :preview-teleported="true" style="width: 30px;height: 30px"></el-image>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="name" label="項目名稱" show-overflow-tooltip
                                        min-width="200"></el-table-column>

                                </LyTableSelect>
                            </template>
                            <template #default="scope">
                                <div class="ly-form-table-handle">
                                    <span>{{ scope.$index + 1 }}</span>
                                    <el-button type="danger" icon="delete" size="small" plain circle
                                        @click="rowDel(scope.row, scope.$index)"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="項目名稱" show-overflow-tooltip min-width="200"></el-table-column>
                        <el-table-column width="200" prop="nums" label="次數">
                            <template #default="scope">
                                <lyInputNumber v-model="scope.row.nums" style="width: 100%;" :controls="false"
                                    :precision="0" size="small">
                                </lyInputNumber>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label="套組金額：" prop="tz_amount">
                    <div style="display: flex;align-items: center;width: 100%;">
                        <lyInputNumber v-model="formData.tz_amount" style="width: 100%;" :controls="false" :precision="2">
                        </lyInputNumber>NT$
                    </div>
                </el-form-item>
                <el-form-item label="图片：" prop="img">
                    <LyUploadAvatar v-model="formData.img" width="100px" height="100px"></LyUploadAvatar>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { twymTaogroupAdd, twymTaogroupEdit, twymProgram } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { deepClone } from "@/utils/util"
import lyInputNumber from "@/components/lyInputNumber.vue";
import LyTableSelect from "@/components/lyTableSelect";
export default {
    components: { LyDialog, LyUploadAvatar, lyInputNumber, LyTableSelect },
    emits: ['refreshData', 'closed'],
    name: "moduleGroup",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                name: '',
                original_price:null,
                xj_amount: null,
                send_amount: null,
                tz_amount: null,
                img: '',
                data: [],
            },
            rules: {
                name: [
                    { required: true, message: '请填写套組名稱', trigger: 'blur' }
                ],
                original_price: [
                    { required: true, message: '请填原價', trigger: 'blur' }
                ],
                xj_amount: [
                    { required: true, message: '请填現金金額', trigger: 'blur' }
                ],
                send_amount: [
                    { required: true, message: '请填產品儲值金額', trigger: 'blur' }
                ],
                tz_amount: [
                    { required: true, message: '请填套組金額', trigger: 'blur' }
                ],
                img: [
                    { required: true, message: '请上传图片', trigger: 'blur' }
                ],
            },
            typeList: []
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        /** 添加表格操作 */
        rowDel(row, index) {
            this.formData.data.splice(index, 1)
        },
        getGoodsApi() {
            return twymProgram
        },
        handleManualSelectClick() {
            this.$refs.lymanualSelect.handleChooseClick()
        },
        handleManualConfirmClick(items) {
            let newItems = deepClone(items)
            //去重赋值
            if (newItems.length > 0) {
                newItems.forEach(item => {
                    let newObj = { id: item.id, name: item.name, nums: 1 }
                    if (this.formData.data.length == 0) {
                        this.formData.data.push(newObj)
                    } else {
                        //如果不存在返回值为-1(去重)
                        if (this.formData.data.findIndex(item1 => item1.id === item.id) == -1) {
                            this.formData.data.push(newObj)
                        }
                    }
                })
            }
        },
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        twymTaogroupEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.$emit('refreshData')
                                this.handleClose()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        twymTaogroupAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.$emit('refreshData')
                                this.handleClose()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}

.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}

.ly-form-table-handle {
    text-align: center;
}

.ly-form-table-handle span {
    display: inline-block;
}

.ly-form-table-handle button {
    display: none;
}

.hover-row .ly-form-table-handle span {
    display: none;
}

.hover-row .ly-form-table-handle button {
    display: inline-block;
}
</style>

