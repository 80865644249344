<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="568px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="項目名稱：" prop="name">
                    <el-input v-model.trim="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="图片：" prop="img">
                    <LyUploadAvatar v-model="formData.img" width="100px" height="100px"></LyUploadAvatar>
                </el-form-item>
                <el-form-item label="金額：" prop="amount">
                    <div style="display: flex;align-items: center;width: 100%;">
                        <lyInputNumber v-model="formData.amount" style="width: 100%;" :controls="false" :precision="2">
                        </lyInputNumber>NT$
                    </div>
                </el-form-item>
                <el-form-item label="美容师开单提成：" prop="tc">
                    <div style="display: flex;align-items: center;width: 100%;">
                        <lyInputNumber v-model="formData.tc" style="width: 100%;" :controls="false" :precision="2">
                        </lyInputNumber>NT$
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { twymProgramAdd, twymProgramEdit } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { deepClone } from "@/utils/util"
import TEditor from '@/components/TEditor'
import lyInputNumber from "@/components/lyInputNumber.vue";
export default {
    components: { LyDialog, LyUploadAvatar, TEditor, lyInputNumber },
    emits: ['refreshData', 'closed'],
    name: "moduleProgram",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                name: '',
                img: '',
                amount: null,
                tc: null,
            },
            rules: {
                name: [
                    { required: true, message: '请填写項目名稱', trigger: 'blur' }
                ],
                img: [
                    { required: true, message: '请上传图片', trigger: 'blur' }
                ],
                amount: [
                    { required: true, message: '请填金額', trigger: 'blur' }
                ],
                tc: [
                    { required: true, message: '请填美容师开单提成', trigger: 'blur' }
                ],
            },
            typeList: []
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        twymProgramEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.$emit('refreshData')
                                this.handleClose()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        twymProgramAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.$emit('refreshData')
                                this.handleClose()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

