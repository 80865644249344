<template>
    <div v-dialogDrag>
        <el-dialog :title="loadingTitle" v-model="dialogVisible" width="680px" top="20px" :destroy-on-close="true"
            :close-on-click-modal="false" :before-close="handleClose">
            <el-form :inline="false" :model="formData" ref="rulesForm" label-position="right" label-width="auto"
                :disabled="true">
                <el-form-item prop="content">
                    <div>
                        <p style="text-indent: 28px;"><span
                                style="color: #333333; font-family: Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;"><span
                                    style="font-size: 14px;">超新肌主要是來自德國透過皮膚科醫師與藥師等創研背景針 對亞熱帶海島型氣候所研發，特別於敏感肌、痘痘肌、毛孔、
                                    蠟黃、以及毛囊炎等肌膚狀況均可安心使用</span></span></p>
                        <p style="text-indent: 28px;"><span
                                style="color: #333333; font-family: Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;"><span
                                    style="font-size: 14px;">術前須知：</span></span></p>
                        <p style="text-indent: 28px;"><span
                                style="color: #333333; font-family: Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;"><span
                                    style="font-size: 14px;">一.做完超新肌後，因需讓細胞汰舊換新所以需要有「五天」的修復期，等同於打完雷射一樣是不能上妝。超新肌術後無需防曬、臉部需不碰水五天，保養品都暫停使用五天。主要原理是要讓臉部不好的細胞給汰換掉，以及表面上的疹子、痘痘縮乾，等於讓這些不好的老廢細胞及角質缺乏養分而死，所以需五天不碰水！！請把重要場合、需要上妝的重要場合避開喔！</span></span>
                        </p>
                        <p style="text-indent: 28px;"><span
                                style="color: #333333; font-family: Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;"><span
                                    style="font-size: 14px;">二.第一堂超新肌會比較不舒服，刺熱感灼熱感都均正常現象，包含做完會有靜電感，但均在24-48小時慢慢消退，因人而異，請客人放心！</span></span>
                        </p>
                        <p style="text-indent: 28px;"><span
                                style="color: #333333; font-family: Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;"><span
                                    style="font-size: 14px;">三、第二天的膚色會稍微變黑，均為正常代謝狀況，第三或第四天才會漸漸脫皮，因每個人的皮膚條件狀況不同，脫皮狀況也會有所不同，無論是否有脫皮都請勿臉部碰水。</span></span>
                        </p>
                        <p style="text-indent: 28px;"><span
                                style="color: #333333; font-family: Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;"><span
                                    style="font-size: 14px;">有部分術後的人，皮膚不營養的地方，或過去有長過汗皰疹、其他疹子、痘痘膿包處，會稍微代謝疹子、小膿包上來均為正常現象，請勿緊張過度，有任何問題都可以詢問官方&amp;服務人員</span></span>
                        </p>
                        <p style="text-indent: 28px;"><span
                                style="color: #333333; font-family: Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;"><span
                                    style="font-size: 14px;">以上重要的術前註意事項請客人幫我詳細閱讀，做完療程以後，都會在衛教一次，所以請客人放心，如有任何狀況及問題，可至官方LINE詢問。</span></span>
                        </p>
                        <p style="text-indent: 28px;"><span
                                style="color: #333333; font-family: Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;"><span
                                    style="font-size: 14px;">本人已仔细阅读上述说明，並充分了解疗程术前后之注意事项，同意遵守上述疗程指示</span></span></p>
                    </div>
                </el-form-item>
                <el-form-item label="客戶簽字：" prop="sign">
                    <el-image :src="formData.sign" preview-teleported :preview-src-list="[formData.sign]"></el-image>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { getCustomerSign } from "@/api/api";
export default {
    name: "moduleDetailZY",
    emits: ['refreshData'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            formData: {
                sign: '',
                content: ''
            },
        }
    },
    methods: {

        handleClose() {
            this.dialogVisible = false
            this.loadingSave = false
            this.$emit('refreshData')
        },
        addUserFn(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true

            this.getDetail(item.id)
        },
        getDetail(id) {
            getCustomerSign({ user_id: id }).then(res => {
                this.formData.sign = res.data.zy_sign
            })
        }
    }
}
</script>

