<template>
    <div v-dialogDrag>
        <el-dialog :title="loadingTitle" v-model="dialogVisible" width="680px" top="20px" :destroy-on-close="true"
            :close-on-click-modal="false" :before-close="handleClose">
            <el-form :inline="false" :model="formData" ref="rulesForm" label-position="right" label-width="auto"
                :disabled="true">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item>
                            您覺得您目前的膚况有什麼問題呢?
                            <el-checkbox-group v-model="formData.ty_content.value1">
                                <el-checkbox label="敏感" />
                                <el-checkbox label="痘痘" />
                                <el-checkbox label="蜷黄" />
                                <el-checkbox label="乾燥" />
                                <el-checkbox label="出油" />
                                <el-checkbox label="粗糙" />
                                <el-checkbox label="暗沉" />
                                <el-checkbox label="毛孔" />
                                <el-checkbox label="其他" />
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            您的皮虐是否在舞偏口状熊鲁出现以下症状?
                            <el-checkbox-group v-model="formData.ty_content.value2">
                                <el-checkbox label="過敏" />
                                <el-checkbox label="紅疹" />
                                <el-checkbox label="瘙癢" />
                                <el-checkbox label="紅腫" />
                                <el-checkbox label="脱屑" />
                                <el-checkbox label="脱皮" />
                                <el-checkbox label="泛红" />
                                <el-checkbox label="突起會癢的腫包" />
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            目前您已知的過敏源有?
                            <el-checkbox-group v-model="formData.ty_content.value3">
                                <el-checkbox label="蛋白" />
                                <el-checkbox label="小麥" />
                                <el-checkbox label="花生" />
                                <el-checkbox label="蝦蟹" />
                                <el-checkbox label="蜂蜜" />
                                <el-checkbox label="牛奶" />
                                <el-checkbox label="杏仁" />
                                <el-checkbox label="其他" />
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            目前或曾您是否有做遇其他皮虐治潦程呢?
                            <el-checkbox-group v-model="formData.ty_content.value4">
                                <el-checkbox label="藻針" />
                                <el-checkbox label="杏仁酸" />
                                <el-checkbox label="矽晶" />
                                <el-checkbox label="韩式皮肤管理" />
                                <el-checkbox label="雷射" />
                                <el-checkbox label="A酸" />
                                <el-checkbox label="其他" />
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            超新肌&璃钢皙皮虐治瘴遏程中鲁有腺部炎症反愿(缸厘熟痛瘪、乾燥脱皮脱屑、缸疹等现象)您是否能接受道檬的反應?
                            <el-checkbox-group v-model="formData.ty_content.value5">
                                <el-checkbox label="1">可以接受</el-checkbox>
                                <el-checkbox label="0">不能接受</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            您是否有飲酒或抽烟的習慣呢?
                            <el-checkbox-group v-model="formData.ty_content.value6">
                                <el-checkbox label="飲酒" />
                                <el-checkbox label="抽煙" />
                                <el-checkbox label="兩者都有" />
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            您平的睡眠習慣是?
                            <el-checkbox-group v-model="formData.ty_content.value7">
                                <el-checkbox label="熟睡" />
                                <el-checkbox label="淺睡" />
                                <el-checkbox label="睡後疲憊" />
                                <el-checkbox label="熬夜(24點後入睡)" />
                                <el-checkbox label="經常失眠" />
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            目前您有在服用的藥物有?
                            <el-checkbox-group v-model="formData.ty_content.value8">
                                <el-checkbox label="無" />
                                <el-checkbox label="激素類" />
                                <el-checkbox label="保健類" />
                                <el-checkbox label="其他" />
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="客戶簽名：">
                            <el-image :src="formData.ty_sign" preview-teleported
                                :preview-src-list="[formData.ty_sign]"></el-image>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { getCustomerSign } from "@/api/api";
export default {
    name: "moduleDetailTYS",
    emits: ['refreshData'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            formData: {
                ty_sign: '',
                ty_content: {
                    value1: [],
                    value2: [],
                    value3: [],
                    value4: [],
                    value5: [],
                    value6: [],
                    value7: [],
                    value8: [],
                }
            },
        }
    },
    methods: {

        handleClose() {
            this.dialogVisible = false
            this.loadingSave = false
            this.$emit('refreshData')
        },
        addUserFn(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true

            this.getDetail(item.id)
        },
        getDetail(id) {
            getCustomerSign({ user_id: id }).then(res => {
                this.formData.ty_sign = res.data.ty_sign
                this.formData.ty_content = res.data.ty_content
            })
        }
    }
}
</script>

